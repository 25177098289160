import { Socials } from './socials';
import { Copyright } from './copyright';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <a href="https://idea.gear-tech.io/programs" target="_blank" rel="noreferrer">
          Idea Portal
        </a>

        <a href="https://wiki.gear-tech.io/" target="_blank" rel="noreferrer">
          Wiki
        </a>
      </div>

      <Copyright />
      <Socials />
    </footer>
  );
}

export { Footer };
