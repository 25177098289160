import { InputProps } from '@gear-js/ui';
import clsx from 'clsx';
import inputStyles from 'components/ui/input/Input.module.scss';
import { Button, PointsBalance, VaraBalance } from 'components';
import { useAccount } from '@gear-js/react-hooks';
import styles from './Input.module.scss';

type Props = Omit<InputProps, 'size' | 'color'> & {
  balance: string;
  onMaxClick?: () => void;
};

export function Input({ onMaxClick, balance, error, ...attrs }: Props) {
  const { account } = useAccount();
  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(inputStyles.wrapper, styles.input)}
        aria-invalid={!!error}
        data-disabled={attrs.readOnly || attrs.disabled}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input type="text" {...attrs} />

        <div className={styles.coin}>
          {onMaxClick ? (
            <PointsBalance value="Bonus tokens" unit="" className={styles.coinType} />
          ) : (
            <VaraBalance value={account?.balance.unit || 'TVARA'} className={styles.coinType} />
          )}

          <div className={styles.coinValue}>
            <span>Balance:</span>
            <b>{balance}</b>

            {onMaxClick && (
              <Button variant="text" className={styles.maxButton} onClick={onMaxClick} disabled={!attrs.value}>
                Use max
              </Button>
            )}
          </div>
        </div>
      </div>

      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
