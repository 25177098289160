import { atom } from 'jotai';

export const AUTH_API_ADDRESS = process.env.REACT_APP_AUTH_API_ADDRESS as string;

export const AUTH_MESSAGE = 'VARA';

export const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'authToken';

export const AUTH_TOKEN_ATOM = atom('');
export const DISCORD_USERNAME_ATOM = atom('');
export const IS_AUTH_READY_ATOM = atom(false);
