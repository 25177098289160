import { useEffect, useState } from 'react';
import { useAccount } from '@gear-js/react-hooks';
import { Link } from 'react-router-dom';
import styles from './Activities.module.scss';
import { useActivities, useActivitiesState } from './hooks';
import { DiscordActivity } from './components/discord-activity';
import { ActivityItem } from './components/activity-item';
import { buttonVariants, Loader } from '../../components';
import { ActivityCard } from './components/activity-card';
import { getActivityStatus } from './utils';
import { toNumber } from '../../utils';
import { ROUTES } from '../../consts';

export function Activities() {
  const { account } = useAccount();
  const { userToken, activities } = useActivities();
  const activitiesState = useActivitiesState();
  const [isLastActivityCompleted, setIsLastActivityCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!userToken?.attribUrl || !activities.length) return;
    const lastActivityStatus = getActivityStatus({
      activity: activities[activities.length - 1],
      history: userToken?.attribUrl,
    });
    setIsLastActivityCompleted(lastActivityStatus === 'completed');
  }, [activities, userToken?.attribUrl]);

  return (
    <>
      <ul className={styles.activities}>
        <li>
          <DiscordActivity />
        </li>
        {Math.floor(toNumber(account?.balance.value || '0')) === 0 && (
          <li>
            <ActivityCard
              status="active"
              title="Get Vara Testnet tokens"
              description="Go to Faucet and get the test balance to start activities.">
              <Link to={ROUTES.FAUCET} className={buttonVariants()}>
                Faucet
              </Link>
            </ActivityCard>
          </li>
        )}
        {activitiesState && (
          <>
            {activities?.map((activity, i) => {
              const isStart = i === 0;
              return (
                <li key={`${activity.title}-${activity.startDate}`}>
                  <ActivityItem
                    isStart={isStart}
                    activity={activity}
                    status={getActivityStatus({ isStart, activity, history: userToken?.attribUrl })}
                  />
                </li>
              );
            })}
            {(!activities.length || isLastActivityCompleted) && userToken && (
              <li>
                <ActivityCard title="New activities will be available soon, stay tuned" status="active" />
              </li>
            )}
          </>
        )}
      </ul>
      {!activitiesState && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </>
  );
}
