import { Link, NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as LogoSVG } from 'assets/images/logo.svg';
import { ROUTES } from 'consts';
import { useAuth } from 'features/auth';
import { Points } from 'features/points-balance';
import { Wallet } from 'features/wallet';
import { useAccount } from '@gear-js/react-hooks';
import styles from './Header.module.scss';

export function Header() {
  const { pathname } = useLocation();

  const { isAuthReady, authToken } = useAuth();
  const { account } = useAccount();

  const linkClassName = clsx(styles.link, !authToken && isAuthReady && styles.disabled);

  return (
    <header className={styles.header}>
      <Link to={ROUTES.HOME}>
        <LogoSVG />
      </Link>

      <nav className={clsx(styles.menu, !isAuthReady && styles.disabled)}>
        <ul>
          <li>
            <NavLink to={ROUTES.LEADERBOARD} className={styles.link}>
              Leaderboard
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.FAUCET} className={linkClassName}>
              Faucet
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.ACTIVITIES} className={linkClassName}>
              Activities
            </NavLink>
          </li>
          <li>
            <a
              href={`https://nft.vara-network.io/?node=wss%3A%2F%2Fvit.vara-network.io&account=${account?.address}`}
              target="_blank"
              rel="noreferrer"
              className={linkClassName}>
              My NFT
            </a>
          </li>
          <li>
            <NavLink to={ROUTES.SWAP} className={linkClassName}>
              Swap
            </NavLink>
          </li>
        </ul>
      </nav>

      {isAuthReady && pathname !== ROUTES.SIGN_UP && (
        <div className={styles.user}>
          <Points className={styles.points} />
          <Wallet className={styles.balance} />
        </div>
      )}
    </header>
  );
}
