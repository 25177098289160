import { useAccount, useApi } from '@gear-js/react-hooks';
import { useEffect } from 'react';
import { CreateType } from '@gear-js/api';
import { formatBalance } from '@polkadot/util';
import { HexString } from '@polkadot/util/types';
import { atom, useAtomValue, useSetAtom } from 'jotai';

type ISystemAccount = {
  consumers: number; // 0
  data: {
    feeFrozen: number | HexString; // 0
    free: number | HexString; // '0x...'
    miscFrozen: number | HexString; // 0
    reserved: number | HexString; //  8327965542000
  };
  nonce: number; // 94
  providers: number; // 1
  sufficients: number; // 0
};

const IS_AVAILABLE_BALANCE_READY = atom<boolean>(false);
const AVAILABLE_BALANCE = atom<undefined | { value: string; unit: string }>(undefined);

export function useAccountAvailableBalance() {
  const isAvailableBalanceReady = useAtomValue(IS_AVAILABLE_BALANCE_READY);
  const availableBalance = useAtomValue(AVAILABLE_BALANCE);
  const setAvailableBalance = useSetAtom(AVAILABLE_BALANCE);
  return { isAvailableBalanceReady, availableBalance, setAvailableBalance };
}

export function useAccountAvailableBalanceSync() {
  const { isAccountReady, account } = useAccount();
  const { api, isApiReady } = useApi();

  const isReady = useAtomValue(IS_AVAILABLE_BALANCE_READY);
  const setIsReady = useSetAtom(IS_AVAILABLE_BALANCE_READY);
  const setAvailableBalance = useSetAtom(AVAILABLE_BALANCE);

  useEffect(() => {
    if (!api || !isApiReady || !isAccountReady) return;

    if (account) {
      api.query.system.account(account.decodedAddress).then((res) => {
        const systemAccount = res.toJSON() as ISystemAccount;
        const total = CreateType.create('u128', systemAccount.data.free).toString();
        const fee = CreateType.create('u128', systemAccount.data.feeFrozen).toString();

        const getBalance = (b: string) => () => {
          const [unit] = api.registry.chainTokens;
          const [decimals] = api.registry.chainDecimals;

          const value = formatBalance(b.toString(), {
            decimals,
            forceUnit: unit,
            withSiFull: false,
            withSi: false,
            withUnit: unit,
          });

          return { value, unit };
        };

        setAvailableBalance(getBalance(`${+total - +fee}`));
        if (!isReady) setIsReady(true);
      });
    } else setIsReady(true);
  }, [account, api, isAccountReady, isApiReady, isReady, setAvailableBalance, setIsReady]);
}
