import { Heading, Text, TextGradient } from 'components';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmailResendButton, useAuth } from 'features/auth';
import styles from './Verify.module.scss';
import { ROUTES } from '../../consts';

function Verify() {
  const { verify } = useAuth();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isVerifying = searchParams.get('verifying');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isVerifying) {
      if (!token) return navigate(ROUTES.HOME);
      verify(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isVerifying]);

  return (
    <div className={styles.container}>
      <Heading>
        <TextGradient>Verify Your Email</TextGradient>
      </Heading>

      <div className={styles.subheadings}>
        <Text size="lg">We sent an email. Please click on the link in the email to confirm your registration.</Text>
        <Text size="lg">
          If the registration is not confirmed within 24 hours, the account will be automatically deleted.
        </Text>
      </div>

      <EmailResendButton />
    </div>
  );
}

export { Verify };
