const ADDRESS = {
  NODE: process.env.REACT_APP_NODE_ADDRESS as string,
};

const ROUTES = {
  HOME: '/',
  LEADERBOARD: '/leaderboard',
  ACTIVITIES: '/activities',
  FAUCET: '/faucet',
  SWAP: '/swap',
  VERIFY: '/confirmation',
  SIGN_UP: '/registration',
  NOTFOUND: '*',
};

export { ADDRESS, ROUTES };
