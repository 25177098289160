import { ReactComponent as CoinSVG } from 'assets/images/icons/coin.svg';
import { ReactComponent as StarSVG } from 'assets/images/icons/star.svg';
import clsx from 'clsx';
import type { SVGComponent } from 'types';
import styles from './Balance.module.scss';

type Props = {
  className?: string;
  SVG: SVGComponent;
  value: string;
  unit?: string;
};

type HOCProps = Omit<Props, 'SVG' | 'children'>;

function Balance({ SVG, value, unit, className }: Props) {
  return (
    <span className={clsx(styles.wrapper, className)}>
      <SVG />
      <span className={styles.balance}>
        <b className={styles.amount}>{value}</b>
        {unit && <span className={styles.unit}>{unit}</span>}
      </span>
    </span>
  );
}

export function VaraBalance({ ...props }: HOCProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Balance SVG={CoinSVG} {...props} />;
}

export function PointsBalance({ unit = 'PPV', ...props }: HOCProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Balance SVG={StarSVG} unit={unit} {...props} />;
}
