import { useReadState, useStateMetadata } from 'hooks';
import { useAccount, useReadWasmState } from '@gear-js/react-hooks';
import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { HexString } from '@polkadot/util/types';
import metaActivities from './assets/meta/activities.meta.txt';
import metaNFT from './assets/meta/nft.meta.txt';
import metaWasm from './assets/meta/market_nft_state.meta.wasm';
import { ACTIVITIES_ADDRESS, NFT_ADDRESS } from './consts';
import type { IActivitiesState, NFTContractState } from './types';
import { getUserToken } from './utils';
import { ACTIVITIES_ATOM, USER_TOKEN_ATOM } from './store';

export function useActivitiesState() {
  return useReadState<IActivitiesState>({ programId: ACTIVITIES_ADDRESS, meta: metaActivities }).state;
}

export function useNFTState() {
  const { account } = useAccount();
  const metawasm = useStateMetadata(metaWasm);
  const { state: programId } = useReadWasmState<undefined | HexString>(
    NFT_ADDRESS,
    metawasm?.buffer,
    'get_storage_id',
    account?.decodedAddress,
  );
  // console.log({ programId });
  return useReadState<NFTContractState>({ programId, meta: metaNFT }).state;
}

export function useActivities() {
  const setUserToken = useSetAtom(USER_TOKEN_ATOM);
  const userToken = useAtomValue(USER_TOKEN_ATOM);
  const setActivities = useSetAtom(ACTIVITIES_ATOM);
  const activities = useAtomValue(ACTIVITIES_ATOM);
  return {
    setUserToken,
    userToken,
    setActivities,
    activities,
  };
}

export function useActivitiesSync() {
  const { account } = useAccount();
  const stateActivities = useActivitiesState();
  const stateNFT = useNFTState();
  const { setActivities, setUserToken } = useActivities();

  useEffect(() => {
    setUserToken(getUserToken(stateNFT, account?.decodedAddress));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.decodedAddress, stateNFT]);

  useEffect(() => {
    setActivities(stateActivities?.activities || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateActivities?.activities]);
}
