import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'consts';
import { useAuth } from 'features/auth';
import { Home } from './home';
import { Leaderboard } from './leaderboard';
import { Activities } from './activities';
import { Swap } from './swap';
import { Verify } from './verify';
import { SignUp } from './sign-up';
import { Faucet } from './faucet';
import { NotFound } from './not-found';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { authToken } = useAuth();

  return authToken ? children : <Home />;
}

function Routing() {
  const { authToken, isAwaitingVerification } = useAuth();

  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />

      <Route path={ROUTES.LEADERBOARD} element={<Leaderboard />} />

      <Route
        path={ROUTES.ACTIVITIES}
        element={
          <PrivateRoute>
            <Activities />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.SWAP}
        element={
          <PrivateRoute>
            <Swap />
          </PrivateRoute>
        }
      />

      <Route path={ROUTES.SIGN_UP} element={!authToken && !isAwaitingVerification ? <SignUp /> : <Home />} />

      <Route path={ROUTES.VERIFY} element={<Verify />} />

      <Route
        path={ROUTES.FAUCET}
        element={
          <PrivateRoute>
            <Faucet />
          </PrivateRoute>
        }
      />

      <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
    </Routes>
  );
}

export { Routing };
