import { Link } from 'react-router-dom';
import { buttonVariants } from 'components';
import { useAccount } from '@gear-js/react-hooks';
import { ActivityCard } from './activity-card';
import { IActivity, IActivityCardStatus } from '../types';
import { useAuth } from '../../auth';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '../../auth/consts';
import { ButtonProps } from '../../../components/ui/button/button';

type ActivityItemProps = { activity: IActivity; status: IActivityCardStatus; isStart: boolean };

export function ActivityItem({ activity: { description, actions, title }, status, isStart }: ActivityItemProps) {
  const { authToken } = useAuth();
  const { account } = useAccount();
  const isExternal = (link: string) => link.includes('http');

  const getURL = (link: string, auth: boolean) => {
    if (isStart) return `${link}&account=${account?.address}`;
    if (auth)
      return `${link}?${AUTH_TOKEN_LOCAL_STORAGE_KEY}=${authToken}&account=${account?.address}&wallet=${account?.meta.source}`;
    return link;
  };

  return (
    <ActivityCard description={description} title={title} status={status}>
      {actions.map((action) => (
        <Link
          key={action.link}
          to={getURL(action.link, action.withAuth)}
          target={isExternal(action.link) ? '_blank' : '_self'}
          aria-disabled={(action.once && status === 'completed') || status === 'disabled'}
          className={buttonVariants({
            width: 'full',
            variant: (action.color?.toLowerCase() as ButtonProps['variant']) ?? 'primary',
          })}>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          {/* {isStart && <>{status !== 'completed' ? action.labelActive : action.labelInactive}</>} */}
          {status === 'completed' && action.labelInactive ? action.labelInactive : action.labelActive}
        </Link>
      ))}
    </ActivityCard>
  );
}
