import { useAlert } from '@gear-js/react-hooks';
import { useProgramMetadata, useStateMetadata, useReadState } from './api';

function useCopyToClipboard() {
  const alert = useAlert();

  function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');

      if (successful) {
        alert.success('Copied');
      } else {
        alert.error('Text not copied. Something went wrong');
      }
    } catch (err) {
      alert.error('Text not copied. Something went wrong');
    }

    document.body.removeChild(textArea);
  }
  function copyToClipboard(text: string) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        alert.success('Copied');
      },
      () => {
        alert.error('Text not copied. Something went wrong');
      },
    );
  }

  return { copyToClipboard };
}

export { useProgramMetadata, useStateMetadata, useReadState, useCopyToClipboard };
