import { Activities as List } from 'features/activities';
import { Heading, Text, TextGradient } from 'components';
import styles from './Activities.module.scss';

function Activities() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Heading>
          <TextGradient>Testnet Launch Activities</TextGradient>
        </Heading>

        <div>
          <Text size="lg">Complete activities to get rewards and unlock the next ones.</Text>
          <Text size="lg">Each week, new activities from the list will become available.</Text>
        </div>
      </header>

      <List />
    </div>
  );
}

export { Activities };
