import { useApi, useAccount } from '@gear-js/react-hooks';
import { Routing } from 'pages';
import { Header, Footer, ApiLoader } from 'components';
import { useAuth, useAuthSync } from 'features/auth';
import { useFTBalance, useFTBalanceSync } from 'features/points-balance';
import { withProviders } from 'hocs';
import { useActivitiesSync } from './features/activities/hooks';
import 'App.scss';
import { useAccountAvailableBalance, useAccountAvailableBalanceSync } from './hooks/use-account-available-balance';

function Component() {
  const { isApiReady } = useApi();
  const { isAccountReady } = useAccount();
  const { isAuthReady } = useAuth();
  const { isFTBalanceReady } = useFTBalance();
  const { isAvailableBalanceReady } = useAccountAvailableBalance();

  useAuthSync();
  useFTBalanceSync();
  useActivitiesSync();
  useAccountAvailableBalanceSync();

  const isAppReady = isApiReady && isAccountReady && isAuthReady && isFTBalanceReady && isAvailableBalanceReady;

  return (
    <>
      <Header />
      <main>{isAppReady ? <Routing /> : <ApiLoader />}</main>
      <Footer />
    </>
  );
}

export const App = withProviders(Component);
