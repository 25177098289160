import { withoutCommas } from '@gear-js/react-hooks';
import { Participants, Participant, ParticipantEntries, ParticipantEntry } from './types';

const sortUsers = ([, value]: ParticipantEntry, [, nextValue]: ParticipantEntry) => {
  if (value.totalPoints === nextValue.totalPoints) {
    return +withoutCommas(nextValue.registrationDate) - +withoutCommas(value.registrationDate);
  }
  return +withoutCommas(nextValue.totalPoints) - +withoutCommas(value.totalPoints);
};

const getParticipants = (state: Participants) => {
  const entries = Object.entries(state) as ParticipantEntries;

  return entries.sort(sortUsers).map(([address, participant], index) => ({ ...participant, address, rank: index + 1 }));
};

function getPages(participants: Participant[], itemsPerPage: number) {
  const pages = [];
  const pagesCount = Math.ceil(participants.length / itemsPerPage);

  for (let i = 0; i < pagesCount; i += 1) {
    const firstIndex = i * itemsPerPage;
    const lastIndex = firstIndex + itemsPerPage;

    const page = participants.slice(firstIndex, lastIndex);

    pages.push(page);
  }

  return pages;
}

function withSearch(participants: Participant[], searchQuery: string) {
  return participants.filter(({ address, name }) => {
    const lowercaseQuery = searchQuery.toLocaleLowerCase();

    return address.toLocaleLowerCase().includes(lowercaseQuery) || name.toLocaleLowerCase().includes(lowercaseQuery);
  });
}

function getMedal(rank: number) {
  if (rank === 1) return 'gold';
  if (rank === 2) return 'silver';
  if (rank === 3) return 'bronze';
}

export { getParticipants, getPages, withSearch, getMedal };
