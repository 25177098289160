import { Leaderboard as Board } from 'features/leaderboard';
import { Wallet } from 'features/wallet';
import { Heading, buttonVariants, TextGradient, Text } from 'components';
import { useAuth } from 'features/auth';
import { Link } from 'react-router-dom';
import { ROUTES } from 'consts';
import styles from './Leaderboard.module.scss';

function Leaderboard() {
  const { authToken, isAwaitingVerification } = useAuth();

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.title}>
          <Heading>
            Vara Network <TextGradient>Testnet Leaderboard</TextGradient>
          </Heading>
        </div>

        <div className={styles.subheadings}>
          {authToken ? (
            <Text size="lg">
              To receive new points, participate in activities and invite users using your referral link.
            </Text>
          ) : (
            <>
              <Text size="lg">You are currently not part of the Vara Network Testnet.</Text>
              <Text size="lg">
                More information can be found in our{' '}
                <a href="https://discord.com/invite/7BQznC9uD9" target="_blank" rel="noreferrer">
                  Discord
                </a>{' '}
                and{' '}
                <a href="https://t.me/VaraNetwork_Global" target="_blank" rel="noreferrer">
                  Telegram
                </a>
                .
              </Text>
            </>
          )}
        </div>

        {authToken ? (
          <Link to={ROUTES.ACTIVITIES} className={buttonVariants({})}>
            View Activities
          </Link>
        ) : (
          <>
            {isAwaitingVerification && (
              <Link to={`${ROUTES.VERIFY}?verifying=true`} className={buttonVariants({})}>
                Resend email confirmation
              </Link>
            )}

            {!isAwaitingVerification && <Wallet />}
          </>
        )}
      </header>

      <div className={styles.content}>
        <Board />
      </div>
    </div>
  );
}

export { Leaderboard };
