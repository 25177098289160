import { useForm } from '@mantine/form';
import { Button } from 'components/ui/button';
import { useAccount, withoutCommas } from '@gear-js/react-hooks';
import { Loader, Text } from 'components';
import { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from './assets/down-arrow.svg';
import { Input } from './components/input';
import styles from './Swap.module.scss';
import { useFTBalance, useFTMessage } from '../points-balance';
import { useGetSwapBalance, useSwapMessage, useSwapState } from './hooks';
import { SWAP_ADDRESS } from './consts';
import { useAccountAvailableBalance } from '../../hooks/use-account-available-balance';

export function Swap() {
  const { account } = useAccount();
  const { balance: rawBalance } = useFTBalance();
  const handleFTMessage = useFTMessage();
  const state = useSwapState();
  const handleSwapMessage = useSwapMessage();
  const swapBalance = useGetSwapBalance();

  const { availableBalance } = useAccountAvailableBalance();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const balance = withoutCommas(rawBalance);

  const { getInputProps, onSubmit, errors, setFieldValue, values, reset } = useForm({
    initialValues: { points: '0', tokens: '0' },
    validate: {
      points: (value: string) => {
        if (+value > 0) {
          if (/[.,]/.test(value.toString())) return 'Value must be an integer';
          if (+value < 10) return 'Minimum acceptable value is 10';
          return null;
        }
        return 'Field is required';
      },
    },
    validateInputOnChange: true,
  });

  const handleSubmit = onSubmit((v) => {
    setIsLoading(true);
    const onEnd = () => {
      setIsLoading(false);
      reset();
    };

    handleFTMessage(
      {
        Approve: {
          amount: v.points,
          to: SWAP_ADDRESS,
        },
      },
      {
        onSuccess: () => {
          handleSwapMessage({ Swap: v.points }, { onSuccess: onEnd, onError: onEnd });
        },
        onError: onEnd,
      },
    );
  });

  useEffect(() => {
    if (state?.exchangeRate) {
      const divider = 1_000_000_000_000;
      const convertedAmount = +values.points * +withoutCommas(state.exchangeRate);

      setFieldValue('tokens', `${Math.floor((convertedAmount / divider) * 100) / 100}`);
    }
  }, [setFieldValue, state?.exchangeRate, values.points]);

  return account && state ? (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.inputs}>
        <Input
          type="number"
          min="10"
          max={balance}
          step="1"
          balance={balance}
          onMaxClick={() => setFieldValue('points', balance)}
          {...getInputProps('points')}
        />

        <DownArrow width={24} height={24} className={styles.arrow} />

        <Input balance={availableBalance?.value || '0'} {...getInputProps('tokens')} readOnly />
      </div>

      <div className={styles.bottom}>
        <Button
          isLoading={isLoading}
          className={styles.submit}
          type="submit"
          disabled={
            Object.keys(errors).length > 0 || +values.points > +balance || !+balance || swapBalance < +values.tokens
          }>
          Swap
        </Button>
        {!isLoading && swapBalance < +values.tokens && (
          <Text size="xs">Not enough {account.balance.unit} balance in the contract to swap.</Text>
        )}
      </div>
    </form>
  ) : (
    <div className={styles.loading}>
      <Loader />
    </div>
  );
}
