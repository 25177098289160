import { useProgramMetadata, useReadState } from 'hooks';
import { useApi, useSendMessage, withoutCommas } from '@gear-js/react-hooks';
import { formatBalance } from '@polkadot/util';
import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { SWAP_ADDRESS } from './consts';
import meta from './assets/meta/swap.meta.txt';
import { ISwapState } from './types';
import { SWAP_AVAILABLE_TOKENS } from './store';

export function useSwapState() {
  return useReadState<ISwapState>({ programId: SWAP_ADDRESS, meta }).state;
}

export function useSwapMessage() {
  const metadata = useProgramMetadata(meta);
  return useSendMessage(SWAP_ADDRESS, metadata, { isMaxGasLimit: true });
}

export function useGetSwapBalance() {
  const { api } = useApi();
  const balance = useAtomValue(SWAP_AVAILABLE_TOKENS);
  const setBalance = useSetAtom(SWAP_AVAILABLE_TOKENS);

  useEffect(() => {
    api?.balance.findOut(SWAP_ADDRESS).then((b) =>
      setBalance(() => {
        const [unit] = api.registry.chainTokens;
        const [decimals] = api.registry.chainDecimals;

        return +withoutCommas(
          formatBalance(b.toString(), {
            decimals,
            forceUnit: unit,
            withSiFull: false,
            withSi: false,
            withUnit: unit,
          }),
        );
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.balance, api.registry.chainDecimals, api.registry.chainTokens]);

  return balance;
}
