import { useState, useEffect } from 'react';
import { Button } from 'components';
import styles from './CountdownButton.module.scss';

type Props = {
  time: number;
  text: string;
  onClick: (trigger: () => void) => void;
  isLoading?: boolean;
};

const getTime = (value: number | undefined) => {
  if (!value) return;

  const hours = Math.floor(value / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0');

  const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0');

  const seconds = Math.floor((value % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

function useCountdown({ time }: { time: number }) {
  const [countdown, setCountdown] = useState(time);

  useEffect(() => {
    if (!countdown) return;

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        const updatedCountdown = prevCountdown - 1000;
        return updatedCountdown <= 0 ? 0 : updatedCountdown;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  return {
    countdown,
    trigger: () => setCountdown(time),
  };
}

export function CountdownButton({ time, text, onClick, isLoading }: Props) {
  const { countdown, trigger } = useCountdown({ time });

  return (
    <Button width="full" disabled={!!countdown} onClick={() => onClick(trigger)} isLoading={isLoading}>
      <span>{text}</span>
      {!!countdown && <span className={styles.time}>({getTime(countdown)})</span>}
    </Button>
  );
}
