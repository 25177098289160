import { Button as GearButton } from '@gear-js/ui';
import { useAlert } from '@gear-js/react-hooks';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import inputStyles from 'components/ui/input/Input.module.scss';
import { Loader } from 'components';
import { useCopyToClipboard } from 'hooks';
import { useAuth } from '../hooks';
import { fetchAuth } from '../utils';
import { LinkResponse, ShareLinkResponse } from '../types';
import { AUTH_API_ADDRESS } from '../consts';
import { CountdownButton } from '../countdown-button';
import styles from './ReferralLink.module.scss';

function ReferralLink() {
  const alert = useAlert();
  const { authToken } = useAuth();
  const { copyToClipboard } = useCopyToClipboard();

  const [linkState, setLinkState] = useState<LinkResponse>();
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const { link, registeredUserCount, remainingUsersToInvite, code, message: msg, content } = linkState || {};

  const resetLink = () => {
    setIsResetting(true);
    fetchAuth<ShareLinkResponse>('links/generate', 'POST', authToken)
      .then((result) => {
        setLinkState(result);
        alert.success('Link was successfully generated');
      })
      .catch(({ message }: Error) => alert.error(message))
      .finally(() => {
        setIsResetting(false);
      });
  };

  useEffect(() => {
    setLinkState(undefined);

    const url = `${AUTH_API_ADDRESS}/links/share`;

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authToken}` },
    })
      .then((response) => {
        if (!response.ok && response.status !== 400) throw new Error(response.statusText);

        return response.json();
      })
      .then((result) => {
        if (result.errors) {
          setLinkState(result.errors as LinkResponse);
        } else {
          setLinkState(result as LinkResponse);
        }
      })
      .catch(({ message }: Error) => alert.error(message));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <div className={styles.card}>
      {linkState ? (
        <>
          <h3 className={styles.heading}>Referral Link</h3>

          {link && (
            <div className={clsx(inputStyles.wrapper, styles.input)}>
              <p className={styles.link}>{link}</p>

              <GearButton
                text="Copy"
                color="transparent"
                onClick={() => copyToClipboard(linkState?.link)}
                disabled={content?.freeze !== undefined || !!msg}
                className={styles.button}
              />
            </div>
          )}

          {msg ? (
            <p>{msg}</p>
          ) : (
            <p className={styles.text}>
              Users registered using the link: <span className={styles.count}>{registeredUserCount}</span> of{' '}
              {remainingUsersToInvite}
            </p>
          )}

          {(content?.freeze !== undefined || !!msg) && (
            <CountdownButton
              time={content?.freeze || 0}
              text="Generate new link"
              onClick={resetLink}
              isLoading={isResetting}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export { ReferralLink };
