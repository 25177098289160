import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAccount, useAlert } from '@gear-js/react-hooks';
import { Button, Heading, Modal, Text, TextGradient } from 'components';
import { fetchAuth } from '../utils';
import formStyles from '../registration-form/RegistrationForm.module.scss';
import styles from './DiscordUsernameModal.module.scss';
import { useAuth } from '../hooks';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '../consts';

type Props = {
  onClose: () => void;
};

function DiscordUsernameModal({ onClose }: Props) {
  const { authToken, setAuthToken, setDiscordUsername, discordUsername } = useAuth();
  const { account } = useAccount();

  const alert = useAlert();

  const { getInputProps, onSubmit } = useForm({
    initialValues: { username: discordUsername || '' },
    validate: { username: (value: string) => (value ? null : 'Field is required') },
  });

  const handleSubmit = onSubmit(({ username }) => {
    fetchAuth<{
      message: string;
      token: string;
    }>('users/discord/update', 'POST', authToken, {
      publicKey: account?.address,
      discordName: username.includes('#') ? username : `${username}#0`,
    })
      .then(({ message, token }) => {
        alert.success(message);
        localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
        setAuthToken(token);
        setDiscordUsername(username);
        onClose();
      })
      .catch(({ message }: Error) => alert.error(message));
  });

  return (
    <Modal onClose={onClose} className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading>
            <TextGradient>Specify Discord name</TextGradient>
          </Heading>
          <Text size="lg">
            Please specify Discord name to start earning quest rewards from Zealy. Make sure the name is exactly the
            same in Zealy and here.
          </Text>
        </div>

        <form id="discord-form" onSubmit={handleSubmit}>
          <TextInput
            label="Discord username"
            placeholder="username"
            variant="filled"
            className={formStyles.textInput}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps('username')}
          />
        </form>

        <div className={styles.buttons}>
          <Button variant="black" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" form="discord-form">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export { DiscordUsernameModal };
