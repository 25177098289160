import { DiscordUsernameModal, useAuth } from 'features/auth';
import { useState } from 'react';
import { Button, buttonVariants } from 'components';
import { ActivityCard } from './activity-card';

export function DiscordActivity() {
  const { discordUsername } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <ActivityCard
        status={!discordUsername ? 'active' : 'completed'}
        // rewards="300"
        // rewardsCoinType="Points"
        title="Specify Discord name"
        description="Please specify Discord name to start earning quest rewards from Zealy.">
        <Button onClick={open}>{discordUsername ? 'Edit Discord name' : 'Specify Discord name'}</Button>

        <a
          href="https://zealy.io/c/varaincentivisedtestnet/questboard"
          target="_blank"
          rel="noreferrer"
          className={buttonVariants({ variant: 'black' })}>
          Open Zealy
        </a>
      </ActivityCard>
      {isOpen && <DiscordUsernameModal onClose={close} />}
    </>
  );
}
