import { Swap as Form } from 'features/swap';
import { Heading, Text, TextGradient } from 'components';
import { useAccount } from '@gear-js/react-hooks';
import styles from './Swap.module.scss';

function Swap() {
  const { account } = useAccount();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Heading>
          <TextGradient>Bonus Tokens Swap</TextGradient>
        </Heading>

        <div>
          <Text size="lg">Swap your bonus tokens into {account?.balance.unit || 'TVARA'} (testVaratokens).</Text>
          <Text size="lg">
            This {account?.balance.unit || 'TVARA'} is needed to pay for gas in games. To swap the bonus tokens you need
            to have at least 250 {account?.balance.unit || 'TVARA'} on your&nbsp;balance.
          </Text>
        </div>
      </div>

      <Form />
    </div>
  );
}

export { Swap };
