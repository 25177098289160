import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox, SimpleGrid, TextInput } from '@mantine/core';
import Identicon from '@polkadot/react-identicon';
import { useAccount, useAlert } from '@gear-js/react-hooks';
import { useForm } from '@mantine/form';
import { WalletModal } from 'features/wallet';
import { Button, Text } from 'components';
import { ROUTES } from 'consts';
import styles from './RegistrationForm.module.scss';
import { post } from '../utils';

const initialValues = { email: '', name: '', discordName: '', twitter: '', telegram: '', terms: false };

const validate = {
  name: (value: string) => value.trim().length < 2,
  email: (value: string) => !/^\S+@\S+$/.test(value),
  terms: (value: boolean) => (!value ? 'You must accept terms' : null),
};

export function RegistrationForm() {
  const { login } = useAccount();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralToken = searchParams.get('ref');

  const [account, setAccount] = useState<InjectedAccountWithMeta>();
  const alert = useAlert();

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const openWalletModal = () => setIsWalletModalOpen(true);
  const closeWalletModal = () => setIsWalletModalOpen(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({ initialValues, validate });

  const handleSubmit = ({ email, name, discordName, twitter, telegram }: typeof initialValues) => {
    if (!account) return alert.error('Please choose wallet');
    if (!referralToken) return;

    const publicKey = account.address;
    const token = referralToken;

    const socials = [
      twitter && { name: 'Twitter', link: twitter },
      discordName && { name: 'Discord', link: discordName.includes('#') ? discordName : `${discordName}#0` },
      telegram && { name: 'Telegram', link: telegram },
    ];

    setIsLoading(true);

    post('users/register', { name, email, publicKey, socials, token })
      .then(() => login(account))
      .then(() => navigate(`${ROUTES.VERIFY}?verifying=true`))
      .catch(({ message }: Error) => alert.error(message))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return referralToken ? (
    <>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))} className={styles.form}>
        <SimpleGrid cols={account ? 2 : 1} mt="sm" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          {account && (
            <div className={styles.current}>
              <Identicon value={account.address} size={22} theme="polkadot" />
              <span>{account.address}</span>
            </div>
          )}

          <Button variant="outline" onClick={openWalletModal}>
            {account ? 'Change Wallet' : 'Choose Wallet'}
          </Button>
        </SimpleGrid>

        <TextInput
          label="Email"
          placeholder="hello@example.com"
          mt="md"
          name="email"
          variant="filled"
          className={styles.textInput}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...form.getInputProps('email')}
        />

        <SimpleGrid cols={2} mt="sm" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <TextInput
            label="Name"
            placeholder="Your name"
            name="name"
            variant="filled"
            className={styles.textInput}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...form.getInputProps('name')}
          />
          <TextInput
            label="Discord username (optional)"
            placeholder="username"
            name="discordName"
            variant="filled"
            className={styles.textInput}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...form.getInputProps('discordName')}
          />
          <TextInput
            label="Twitter username (optional)"
            placeholder="@vara-network"
            name="twitter"
            variant="filled"
            className={styles.textInput}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...form.getInputProps('twitter')}
          />
          <TextInput
            label="Telegram username (optional)"
            placeholder="@vara-network"
            name="telegram"
            variant="filled"
            className={styles.textInput}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...form.getInputProps('telegram')}
          />
        </SimpleGrid>

        <Checkbox
          label={
            <label htmlFor="privacy-policy">
              I have read and accept the{' '}
              <a href="https://vara-network.io/privacy-policy" target="_blank" rel="noreferrer" className={styles.link}>
                privacy policy
              </a>
              .
            </label>
          }
          id="privacy-policy"
          mt="sm"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...form.getInputProps('terms', { type: 'checkbox' })}
        />

        <Button type="submit" width="full" isLoading={isLoading}>
          Sign Up
        </Button>
      </form>

      {isWalletModalOpen && (
        <WalletModal
          account={account}
          onAccountChange={setAccount}
          onLogout={() => setAccount(undefined)}
          onClose={closeWalletModal}
        />
      )}
    </>
  ) : (
    <Text size="lg">Use link with referral token to register.</Text>
  );
}
