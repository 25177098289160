import { useAlert } from '@gear-js/react-hooks';
import { useEffect, useState } from 'react';
import { Button } from 'components';
import { fetchAuth } from '../utils';
import { useAuth } from '../hooks';
import { AvailableTokensResponse } from '../types';
import styles from './BalanceButton.module.scss';

function BalanceButton() {
  const { authToken } = useAuth();
  const alert = useAlert();

  const [availableClaimsCount, setAvailableClaimsCount] = useState<number>();
  const isClaimsRequestReady = availableClaimsCount !== undefined;
  const isClaimsFinished = availableClaimsCount === 0;

  const [isTokensRequestReady, setIsTokensRequestReady] = useState(true);

  useEffect(() => {
    fetchAuth<AvailableTokensResponse>('tokens/available', 'POST', authToken)
      .then(({ result }) => setAvailableClaimsCount(result))
      .catch(({ message }: Error) => alert.error(message));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const handleClick = () => {
    setIsTokensRequestReady(false);

    fetchAuth('tokens/request', 'POST', authToken)
      .then(() => setAvailableClaimsCount((prevValue) => (prevValue ? prevValue - 1 : undefined)))
      .catch(({ message }: Error) => alert.error(message))
      .finally(() => setIsTokensRequestReady(true));
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={handleClick} disabled={!isClaimsRequestReady || !isTokensRequestReady || isClaimsFinished}>
        {isClaimsFinished ? 'Claimed' : 'Get Test Balance'}
      </Button>

      <p className={styles.claims}>Available Claims: {isClaimsRequestReady ? availableClaimsCount : '...'}</p>
    </div>
  );
}

export { BalanceButton };
