import clsx from 'clsx';
import type { IActivity, IActivityCardStatus } from '../../types';
import styles from './activity-card.module.scss';

type ActivityCardProps = React.PropsWithChildren &
  Pick<IActivity, 'title'> & {
    status: IActivityCardStatus;
    className?: string;
    description?: IActivity['description'];
  };

export function ActivityCard({ children, status, title, description, className }: ActivityCardProps) {
  return (
    <div
      className={clsx(
        styles.activity,
        status === 'active' && styles.active,
        status === 'completed' && styles.completed,
        className,
      )}>
      <div className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {description && <p className={styles.description}>{description}</p>}
      </div>

      {children && <div className={styles.actions}>{children}</div>}

      {/* {rewards && ( */}
      {/*  <p className={styles.rewards}> */}
      {/*    <GiftIcon width={20} height={20} className={styles['rewards-icon']} /> */}
      {/*    <b className={styles['rewards-heading']}> */}
      {/*      Possible rewards: {rewardsCoinType && <span className={styles['rewards-heading-coins']}>up to</span>} */}
      {/*    </b> */}
      {/*    {rewardsCoinType && <PointsBalance value={rewards} className={styles['rewards-coins']} />} */}
      {/*    {!rewardsCoinType && <span>{rewards}</span>} */}
      {/*  </p> */}
      {/* )} */}
    </div>
  );
}
