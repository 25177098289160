import Identicon from '@polkadot/react-identicon';
import clsx from 'clsx';
import { useState } from 'react';
import { useAccount } from '@gear-js/react-hooks';
import { Button, VaraBalance } from 'components';
import { useAccountAvailableBalance } from 'hooks/use-account-available-balance';
import { useAuth } from '../../../auth';
import { WalletModal } from '../wallet-modal';
import styles from './Wallet.module.scss';

export function Wallet({ className }: { className?: string }) {
  const { signIn, signOut } = useAuth();
  const { account, isAccountReady } = useAccount();

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const openWalletModal = () => setIsWalletModalOpen(true);
  const closeWalletModal = () => setIsWalletModalOpen(false);

  const walletClassName = clsx(styles.wallet, className);

  const { availableBalance: balance, isAvailableBalanceReady } = useAccountAvailableBalance();

  return isAccountReady ? (
    <>
      <div className={walletClassName}>
        {account && isAvailableBalanceReady && (
          <VaraBalance value={balance?.value || '0'} unit={account.balance.unit} className={styles.vara} />
        )}

        <Button
          variant={account ? 'black' : 'primary'}
          className={styles.button}
          onClick={openWalletModal}
          disabled={!isAccountReady}>
          {account && <Identicon value={account.address} size={16} theme="polkadot" />}
          <span>{account ? account.meta.name : 'Connect Wallet'}</span>
        </Button>
      </div>

      {isWalletModalOpen && (
        <WalletModal account={account} onAccountChange={signIn} onLogout={signOut} onClose={closeWalletModal} />
      )}
    </>
  ) : null;
}
