import { useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';
import { Button, Input, Loader, PointsBalance, Text } from 'components';
import { useAccount } from '@gear-js/react-hooks';
import { getPages, getParticipants, getMedal, withSearch } from './utils';
import { useLeaderboardState } from './hooks';
import styles from './Leaderboard.module.scss';
import { prettyAddress, toNumber } from '../../utils';
import { ChevronRight, ChevronsRight } from '../../assets/images';

const initialValues = { searchQuery: '' };

function Leaderboard() {
  const { account } = useAccount();

  const state = useLeaderboardState();
  const participants = useMemo(() => getParticipants(state?.users || {}), [state]);
  const participantsCount = participants.length;

  const { getInputProps, values } = useForm({ initialValues });
  const { searchQuery } = values;

  const [pageIndex, setPageIndex] = useState(0);
  const pages = useMemo(() => {
    setPageIndex(0);
    return getPages(withSearch(participants, searchQuery), 10);
  }, [participants, searchQuery]);

  const page = pages[pageIndex];
  const pagesCount = pages.length;
  const pageNumber = pagesCount ? pageIndex + 1 : pageIndex;
  const lastPageIndex = pagesCount ? pagesCount - 1 : pagesCount;
  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === lastPageIndex;

  const prevPage = () => setPageIndex((prevValue) => prevValue - 1);
  const nextPage = () => setPageIndex((prevValue) => prevValue + 1);
  const firstPage = () => setPageIndex(0);
  const lastPage = () => setPageIndex(lastPageIndex);

  const getRows = () =>
    page?.map(({ rank, address, name, registrationDate, totalPoints }) => {
      const medal = getMedal(rank);
      const isActive = address === account?.decodedAddress;
      const rankClassName = clsx(styles.rank, medal && styles[medal]);

      const date = new Date(toNumber(registrationDate)).toLocaleString();

      return (
        <tr key={address} className={clsx(isActive && styles.active, medal && styles['leader-row'])}>
          <td>
            <span className={rankClassName}>{rank}</span>
          </td>
          <td className={styles.nameCell}>
            <span className={styles.name}>{isActive ? 'You' : name}</span> ({prettyAddress(address)})
          </td>
          <td>{date}</td>
          <td>
            <PointsBalance value={totalPoints} className={styles['total-points']} />
          </td>
        </tr>
      );
    });

  return (
    <section className={styles.leaderboard}>
      {state ? (
        <>
          <div className={styles.header}>
            <h3 className={styles.heading}>Total participants: {participantsCount}</h3>
            <Input {...getInputProps('searchQuery')} className={styles.input} />
          </div>

          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles['th-rank']}>#</th>
                <th className={styles['th-name']}>Name and wallet</th>
                <th className={styles['th-date']}>Registration date</th>
                <th className={styles['th-points']}>Total points</th>
              </tr>
            </thead>

            <tbody>{getRows()}</tbody>
          </table>

          <div className={styles.footer}>
            <div className={styles.pagination}>
              <div className={styles['pagination-nav']}>
                <Button variant="outline" className={styles.leftButton} onClick={firstPage} disabled={isFirstPage}>
                  <ChevronsRight />
                </Button>
                <Button variant="outline" className={styles.leftButton} onClick={prevPage} disabled={isFirstPage}>
                  <ChevronRight />
                </Button>
              </div>

              <Text className={styles['pagination-pages']}>
                <span className={styles.pageNumber}>{pageNumber}</span>
                <span className={styles.pagesCount}>out of {pagesCount}</span>
              </Text>

              <div className={styles['pagination-nav']}>
                <Button variant="outline" onClick={nextPage} disabled={isLastPage}>
                  <ChevronRight />
                </Button>
                <Button variant="outline" onClick={lastPage} disabled={isLastPage}>
                  <ChevronsRight />
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
}

export { Leaderboard };
