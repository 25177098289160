import { useAccount, useAlert } from '@gear-js/react-hooks';
import { useState } from 'react';
import { post } from '../utils';
import { CountdownButton } from '../countdown-button';

function EmailResendButton() {
  const { account } = useAccount();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = (trigger: () => void) => {
    if (!account) return;

    const publicKey = account.address;

    setIsLoading(true);

    post('email_confirmation/resend', { publicKey })
      .then(() => alert.success('Email resent'))
      .catch(({ message }) => alert.error(message))
      .finally(() => {
        setIsLoading(false);
        trigger();
      });
  };

  return <CountdownButton time={30000} text="Resend email" onClick={handleClick} isLoading={isLoading} />;
}

export { EmailResendButton };
