import { PointsBalance } from 'components';
import { useAccount } from '@gear-js/react-hooks';
import { useFTBalance } from './hooks';

function Points({ className }: { className?: string }) {
  const { account } = useAccount();
  const { balance } = useFTBalance();

  return account ? <PointsBalance value={balance} className={className} /> : null;
}

export { Points };
