import { AnyJson } from '@polkadot/types/types';
import { AUTH_API_ADDRESS } from './consts';
import { IApiError } from '../../types';

const post = <T>(url: string, payload: AnyJson) =>
  fetch(`${AUTH_API_ADDRESS}/${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    const json = await response.json();
    if (!response.ok) {
      const { message, content } = json as IApiError;
      if (content.errors) {
        if (Array.isArray(content.errors)) throw new Error(content.errors[0].msg);
        throw new Error(message);
      }

      throw new Error((json as IApiError).message);
    }
    return json as T;
  });

const fetchAuth = <T>(url: string, method: string, token: string, payload?: AnyJson) =>
  fetch(`${AUTH_API_ADDRESS}/${url}`, {
    method,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: payload ? JSON.stringify(payload) : undefined,
  }).then(async (response) => {
    const json = await response.json();
    if (!response.ok) throw new Error(await (json as IApiError).message);
    return json as T;
  });

export { post, fetchAuth };
