import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { useAtom } from 'jotai';
import { useAlert, useAccount } from '@gear-js/react-hooks';
import { useEffect, useState } from 'react';
import { web3FromAddress } from '@polkadot/extension-dapp';
import { useNavigate } from 'react-router-dom';
import {
  AUTH_API_ADDRESS,
  AUTH_MESSAGE,
  AUTH_TOKEN_ATOM,
  AUTH_TOKEN_LOCAL_STORAGE_KEY,
  DISCORD_USERNAME_ATOM,
  IS_AUTH_READY_ATOM,
} from './consts';
import { fetchAuth, post } from './utils';
import { AuthResponse, SignInResponse } from './types';
import { IApiError } from '../../types';
import { ROUTES } from '../../consts';

function useAuth() {
  const [authToken, setAuthToken] = useAtom(AUTH_TOKEN_ATOM);
  const [discordUsername, setDiscordUsername] = useAtom(DISCORD_USERNAME_ATOM);
  const [isAuthReady, setIsAuthReady] = useAtom(IS_AUTH_READY_ATOM);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const navigate = useNavigate();
  const { account, login, logout } = useAccount();
  const isAwaitingVerification = account && !authToken;

  const alert = useAlert();

  const signIn = (_account: InjectedAccountWithMeta) => {
    const { address } = _account;

    return web3FromAddress(address)
      .then(({ signer }) => {
        if (!signer.signRaw) throw new Error('signRaw not exists');

        return signer.signRaw({ address, data: AUTH_MESSAGE, type: 'bytes' });
      })
      .then(({ signature }) => signature)
      .then((signature) =>
        fetch(`${AUTH_API_ADDRESS}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ signature, publicKey: address, message: AUTH_MESSAGE }),
        }),
      )
      .then(async (response) => {
        const json = await response.json();
        if (!response.ok && response.status !== 409) throw new Error((json as IApiError).message);
        return json;
      })
      .then((result) => {
        if ((result as IApiError).code === 409 && (result as IApiError).message === 'Please confirm email') {
          login(_account);

          setAuthToken('');
          setDiscordUsername('');
        } else {
          login(_account);

          setAuthToken((result as SignInResponse).accessToken);
          setDiscordUsername((result as SignInResponse).discord || '');
        }
      })
      .catch(({ message }: Error) => alert.error(message));
  };

  const signOut = () => {
    logout();
    setAuthToken('');
  };

  const verify = (token: string) => {
    if (!isVerifying) {
      setIsVerifying(true);
      post<SignInResponse>('email_confirmation/confirm', { token })
        .then(({ accessToken, discord }) => {
          if (account) {
            setAuthToken(accessToken);
            setDiscordUsername(discord || '');
          }
          alert.success('Email confirmed');
          navigate(ROUTES.HOME);
        })
        .catch(({ message }) => alert.error(message))
        .finally(() => setIsVerifying(false));
    }
  };

  const auth = () => {
    const localStorageToken = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);

    if (!localStorageToken) {
      setIsAuthReady(true);
      if (!isAwaitingVerification) logout();
      return;
    }

    fetchAuth<AuthResponse>('auth/me', 'PUT', localStorageToken)
      .then(({ discordName }) => {
        setAuthToken(localStorageToken);
        setDiscordUsername(discordName || '');
      })
      .catch(({ message }: Error) => {
        signOut();
        localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
        alert.error(message);
      })
      .finally(() => setIsAuthReady(true));
  };

  return {
    authToken,
    discordUsername,
    isAuthReady,
    isAwaitingVerification,
    isVerifying,
    setAuthToken,
    signIn,
    signOut,
    auth,
    setDiscordUsername,
    verify,
  };
}

function useAuthSync() {
  const { isAccountReady } = useAccount();
  const { authToken, isAuthReady, auth } = useAuth();

  useEffect(() => {
    if (!isAccountReady) return;

    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountReady]);

  useEffect(() => {
    if (!isAuthReady) return;
    if (!authToken) return localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);

    localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, authToken);
  }, [isAuthReady, authToken]);
}

export { useAuth, useAuthSync };
