import { Heading, Text, TextGradient } from 'components';
import { ReferralLink, BalanceButton } from 'features/auth';
import styles from './Faucet.module.scss';

function Faucet() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Heading>
          Vara Network <TextGradient>Testnet Faucet</TextGradient>
        </Heading>

        <div className={styles.subheadings}>
          <Text size="lg">Get Vara Testnet tokens.</Text>
          <Text size="lg">
            More tokens will be available after each registration of a new account through the referral link generated
            for you. Just share the link.
          </Text>
        </div>

        <BalanceButton />
      </header>

      <ReferralLink />
    </div>
  );
}

export { Faucet };
