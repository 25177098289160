import type { Account } from '@gear-js/react-hooks';
import { withoutCommas } from '@gear-js/react-hooks';
import type { IActivity, IToken, NFTContractState } from './types';
import { IActivityCardStatus } from './types';

export const getUserToken = (stateNFT?: NFTContractState, decodedAddress?: Account['decodedAddress']) => {
  if (!stateNFT && !decodedAddress) return;
  const mintedTokenInfo = stateNFT?.owners?.find((address) => address === decodedAddress);
  if (mintedTokenInfo) {
    return stateNFT?.tokens.find(([, token]) => token.owner === decodedAddress)?.[1];
  }
};

export const getActivityStatus = ({
  isStart = false,
  activity,
  history,
}: {
  isStart?: boolean;
  activity: IActivity;
  history?: IToken['attribUrl'];
}): IActivityCardStatus => {
  const { startDate, dependsOn, doneKey } = activity;
  // Check all activities, except the first one, if NFT is not minted
  if (!isStart && !history?.length) return 'disabled';
  // Check the first activity whether NFT was minted or not
  if (isStart) return history?.length ? 'completed' : 'active';
  // Check if the activity start date has passed
  if (Date.now() < +withoutCommas(startDate)) return 'disabled';

  const getIsConditionsPassed = () => {
    const result: boolean[] = [];
    dependsOn.forEach((d) => result.push(!!history?.find((h) => h.includes(d))));
    return result;
  };
  // Check whether all dependent activities were completed
  if (!getIsConditionsPassed().every(Boolean)) return 'disabled';
  // Check if the activity has completed
  if (history?.find((item) => item.includes(doneKey))) return 'completed';
  return 'active';
};

export const isAllActivitiesCompleted = () => {};
