import { Heading, Text, TextGradient } from 'components';
import { RegistrationForm } from 'features/auth';
import { useAccount } from '@gear-js/react-hooks';
import styles from './SignUp.module.scss';

function SignUp() {
  const { account } = useAccount();

  return (
    <div className={styles.container}>
      <Heading>
        <TextGradient>Vara Network Testnet Launch</TextGradient>
      </Heading>

      <Text size="lg">
        To participate in the Vara Network Testnet Launch, you must own a{' '}
        <a href="https://wiki.vara-network.io/docs/account/create-account/" target="_blank" rel="noreferrer">
          Substrate wallet
        </a>{' '}
        address that can receive the {account?.balance.unit || 'TVARA'} tokens. Please refer to the Wiki for detailed
        instructions on how to create a Substrate wallet.
      </Text>

      <RegistrationForm />
    </div>
  );
}

export { SignUp };
